import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// UI modules
import { MatIconModule } from '@angular/material/icon';

import { AscModuleIconComponent } from './module-icon.component';


@NgModule({
   declarations: [
      AscModuleIconComponent
   ],
   imports: [
      CommonModule,
      MatIconModule
   ],
   exports: [
      AscModuleIconComponent
   ]
})
export class AscModuleIconModule { }
